import React from 'react'
import UiMaskedInput from '../../../../ui/UiMaskedInput'
import useStore from 'stores/useStore'

const getMaskByCountry = {
  BRL: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], // Brasil: XXXXX-XXX
  USD: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // EUA: XXXXX ou XXXXX-XXXX
  EUR: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], // Portugal: XXXX-XXX
}

const MaskedInput = props => {
  const { eventCurrency } = useStore()
  const mask = getMaskByCountry[eventCurrency] || []

  return <UiMaskedInput mask={mask} {...props} />
}

export default MaskedInput
