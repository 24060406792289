import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Box, Paper } from '@material-ui/core'
import { useLocation } from 'wouter'

import { UiCheckbox, UiHbox } from '../../../ui'
import useStore from '../../../stores/useStore'
import Button from '../../../components/Button'
import DocumentTypeInput from '../../../inputs/DocumentTypeInput'
import FieldDocumentFormik from '../../../inputs/formik/FieldDocumentFormik'
import FieldFormik from '../../../inputs/formik/FieldFormik'
import MailCheckInputFormik from '../../../inputs/formik/MailCheckInputFormik'
import { noty } from 'components/SnackbarQueue'
import { PasswordRules } from 'components'

export function SignupForm() {
  const { signUp, hideDocsByCurrency } = useStore()
  const [, setLocation] = useLocation()
  const [showRules, setShowRules] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Nome é obrigatório!').min(5, 'Mínimo de 5 caracteres'),
      email: yup.string().trim().email('Email inválido!').required('Email é obrigatório!'),
      document_type: !hideDocsByCurrency
        ? yup.string()
        : yup.string().required('Tipo de documento é obrigatório!'),
      document_value: !hideDocsByCurrency
        ? yup.string()
        : yup.string().required('Documento é obrigatório!'),
      password: yup.string().required('Senha é obrigatória!'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Senha não confere')
        .required('Confirmação de senha é obrigatória!'),
    })

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        document_type: '',
        document_value: '',
        password: '',
        password_confirmation: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          values.international = !hideDocsByCurrency
          await signUp(values)
          setSubmitting(false)
          setLocation('/participants')
        } catch (error) {
          const authError = error?.errors ? Object.values(error.errors) : null
          setSubmitting(false)
          error?.errors && setErrors(error.errors)
          console.error('errors', authError || error)
          !authError && noty.error(error.message)
        }
      }}>
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={() => handleSubmit()}>
          <Box mb={4}>
            <FieldFormik name="name" label="Nome" required />
            <MailCheckInputFormik name="email" label="E-mail" required />
            {hideDocsByCurrency && (
              <UiHbox>
                <DocumentTypeInput
                  required
                  name="document_type"
                  onChange={value => setFieldValue('document_type', value)}
                  onBlur={handleBlur}
                  value={values.document_type}
                  error={touched.document_type && errors.document_type}
                />
                <FieldDocumentFormik
                  required
                  name="document_value"
                  documentType={values.document_type}
                />
              </UiHbox>
            )}

            <UiHbox onFocus={() => setShowRules(true)}>
              <FieldFormik
                name="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                required
              />
              <FieldFormik
                name="password_confirmation"
                label="Repetir Senha"
                type="password"
                required
              />
            </UiHbox>
            <UiCheckbox label="Mostrar senha" onChange={() => setShowPassword(!showPassword)} />
            {showRules && (
              <Paper elevation={0} style={{ background: 'transparent' }}>
                <PasswordRules fieldPassword={values.password} />
              </Paper>
            )}
          </Box>
          <Button
            type="submit"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
            size="large"
            color="secondary"
            variant="contained"
            fullWidth>
            CRIAR CONTA
          </Button>
        </form>
      )}
    </Formik>
  )
}
