import store from 'stores/store'

export default function formatCurrency(value = 0) {
  // FIXME: Tentar acessar pelo hook useStore
  const eventCurrency = store?.event?.currency

  const currencySymbols = {
    BRL: 'R$',
    USD: '$',
    EUR: '€',
  }

  const symbol = currencySymbols[eventCurrency] || currencySymbols['BRL']

  return `${symbol} ${(value / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`
}
