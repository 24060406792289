import React, { useEffect } from 'react'
import './style.css'
import { formatCurrency } from '../../../utils'
import calcLeaderDiscount from '../../../business/groupDiscount/calcLeaderDiscount'
import calcGroupDiscount from '../../../business/groupDiscount/calcGroupDiscount'
import useStore from '../../../stores/useStore'
import GTM from 'business/gtm/googleTagManager'

export default function DetailsCell() {
  const { getParticipantOptionals, selectedCategories, groupDiscount, sumWithFee } = useStore()
  const lider = calcLeaderDiscount(groupDiscount, selectedCategories)
  const grupo = calcGroupDiscount(groupDiscount, selectedCategories, lider)

  const gaItems = selectedCategories.map(el => {
    return {
      ...el,
      item_id: el.id,
      item_name: el.name,
      item_category: el.name,
      price: Number(el.price / 100),
    }
  })

  const gaValueBrl = sumWithFee / 100

  useEffect(() => {
    GTM.ViewCart(gaItems, gaValueBrl)
  }, [gaItems, gaValueBrl])

  return (
    <div align="center" className="details-table">
      <span className="title">Resumo do Pedido</span>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Preço</th>
            <th>Qde</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {selectedCategories.map(category => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{formatCurrency(category.price)}</td>
              <td>{category.qty}</td>
              <td>{formatCurrency(category.price * category.qty)}</td>
            </tr>
          ))}
          {getParticipantOptionals?.map(participant => {
            return participant.optionals?.map(optional => (
              <tr key={optional.optionalId}>
                <td>{optional.label}</td>
                <td>{formatCurrency(optional.amount_in_cents)}</td>
                <td>{1}</td>
                <td>{formatCurrency(optional.amount_in_cents)}</td>
              </tr>
            ))
          })}
        </tbody>
        <tbody>
          {lider && (
            <tr>
              <td colSpan={3} align="right">
                Desconto do líder
              </td>
              <td>-{formatCurrency(lider)}</td>
            </tr>
          )}
          {grupo && (
            <tr>
              <td colSpan={3} align="right">
                desconto do grupo
              </td>
              <td>-{formatCurrency(grupo)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
