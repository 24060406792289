import { formatCurrency } from '../utils'
import { calculateInterestAmount } from './financial'

// TODO calcular segundo esse cálculo: https://docs.pagar.me/reference#calculando-pagamentos-parcelados
export default function instalmentOptions(instalments = 12, amount_in_cents = 60000, haveFee, fee) {
  const array = []
  const sem_com = haveFee ? 'com' : 'sem'
  for (let i = 1; i <= instalments; i++) {
    let parcela = haveFee
      ? parseInt(Math.round(calculateInterestAmount(amount_in_cents, i, fee) / i))
      : amount_in_cents / i
    let real = formatCurrency(parcela)
    if (i === 1) {
      array.push([i, `${formatCurrency(amount_in_cents)} à Vista`])
    } else {
      array.push([i, `${i}x de ${real} ${sem_com} juros`])
    }
  }
  return array
}
