import React from 'react'
import { UiButton } from '../../../ui'
import useStore from '../../../stores/useStore'

const CustomLinkButton = () => {
  const { event } = useStore()
  return (
    event?.followup_label && (
      <UiButton title={event?.followup_label} href={event?.followup_url} target="blank" style={{textTransform: 'none', color: 'white'}}>
        {event?.followup_label}
      </UiButton>
    )
  )
}

export default CustomLinkButton
