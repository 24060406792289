import React from 'react'
import { UiButton } from '../../../ui'
import { API_URL } from 'env'
import useStore from '../../../stores/useStore'

const MyTicketsButton = () => {
  const {
    leaderParticipant: { id: code },
  } = useStore()
  const url = API_URL + `/enrollments/${code}`
  return (
    <UiButton title={url} href={url} target="blank" variant="outlined" style={{textTransform: 'none'}} >
      Ver minhas inscrições
    </UiButton>
  )
}

export default MyTicketsButton
