import React from 'react'
import UiSelect from '../../../../ui/UiSelect'
import useStore from 'stores/useStore'
import { BRAZIL_STATES } from 'constants/brazilStates'
import { US_STATES } from 'constants/usStates'
import { PORTUGAL_DISTRICTS } from 'constants/portugalDistricts'

const getStatesByCountry = {
  BRL: BRAZIL_STATES,
  USD: US_STATES,
  EUR: PORTUGAL_DISTRICTS,
}

const StateSelect = ({ ...props }) => {
  const { eventCurrency } = useStore()
  const states = getStatesByCountry[eventCurrency] || getStatesByCountry["BRL"]

  return <UiSelect options={states} {...props} />
}

export default StateSelect
