import React from 'react'
import UiMaskedInput from '../../../../ui/UiMaskedInput'
import useStore from 'stores/useStore'

const getPhoneMaskByCurrency = {
  // Exemplo: (99) 99999-9999
  BRL: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  // Exemplo: +1 (999) 999-9999
  USD: [
    '+',
    '1',
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  // Exemplo: +351 999 999 999
  EUR: ['+', '3', '5', '1', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
}

const MaskedInput = props => {
  const { eventCurrency } = useStore()
  const mask = getPhoneMaskByCurrency[eventCurrency] || getPhoneMaskByCurrency["BRL"]

  return <UiMaskedInput mask={mask} {...props} />
}

export default MaskedInput
