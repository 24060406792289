export const PORTUGAL_DISTRICTS = [
  ['01', 'Aveiro'],
  ['02', 'Beja'],
  ['03', 'Braga'],
  ['04', 'Bragança'],
  ['05', 'Castelo Branco'],
  ['06', 'Coimbra'],
  ['07', 'Évora'],
  ['08', 'Faro'],
  ['09', 'Guarda'],
  ['10', 'Leiria'],
  ['11', 'Lisboa'],
  ['12', 'Portalegre'],
  ['13', 'Porto'],
  ['14', 'Santarém'],
  ['15', 'Setúbal'],
  ['16', 'Viana do Castelo'],
  ['17', 'Vila Real'],
  ['18', 'Viseu'],
  ['20', 'Açores'],
  ['30', 'Madeira'],
]
